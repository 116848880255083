
















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CosmopolChip extends Vue {
  @Prop({ required: false, default: "primary", type: String })
  private type: string;
  @Prop({ required: false, default: "", type: String })
  private icon: string;
  @Prop({ required: false, default: "", type: String })
  private label: string;
  @Prop({ required: false, default: false, type: Boolean })
  private circle: boolean;

  get bgColor(): string {
    switch (this.type) {
      case "error":
        return "var(--v-error-lighten2)";
      case "accent":
        return "var(--v-accent-lighten2)";
      case "success":
        return "var(--v-success-lighten2)";
      case "secondary":
        return "var(--v-secondary-lighten4)";
      default:
        return "var(--v-primary-lighten4)";
    }
  }

  get iconColor(): string {
    switch (this.type) {
      case "error":
        return "var(--v-error-base)";
      case "accent":
        return "var(--v-accent-base)";
      case "success":
        return "var(--v-success-base)";
      case "secondary":
        return "var(--v-secondary-base)";
      default:
        return "var(--v-primary-base)";
    }
  }

  get labelClass(): string {
    switch (this.type) {
      case "error":
        return "c-txt-error-d2";
      case "accent":
        return "c-txt-accent-d2";
      case "success":
        return "c-txt-success-d2";
      case "secondary":
        return "c-txt-secondary-d3";
      default:
        return "c-txt-primary-d3";
    }
  }
}
